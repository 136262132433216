<template>
  <a-layout id="components-layout-demo-fixed-sider">
    <a-layout-sider :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }">
      <div class="logo" >
        <img  src="../../public/images/logo.png" style="height:32px;" />
        <br />
        <div style="margin:8px;color:#fff;">
        欢迎<a-button type="link" @click="openConfig" >{{user.userName}}</a-button> <a-button type="link" icon="logout" @click="logout" ></a-button>
      </div>
      </div>
      
      <a-menu theme="dark" mode="inline" @click="mainMenuClick" :default-selected-keys="[getDefaultNavKey()]">
        
        <a-menu-item key="/main/trail">
          <a-icon type="global" />
          <span class="nav-text">轨迹管理</span>
        </a-menu-item>
        <a-menu-item key="/main/report">
          <a-icon type="file-text" />
          <span class="nav-text">报表管理</span>
        </a-menu-item>

        <!-- <a-menu-item key="3">
          <a-icon type="upload" />
          <span class="nav-text">nav 3</span>
        </a-menu-item> -->
        
      </a-menu>
    </a-layout-sider>
    <a-layout :style="{ marginLeft: '200px' }">
      <!-- <a-layout-header :style="{ background: '#fff', padding: 0 }" /> -->
      <a-layout-content :style="{ margin: '24px 16px 0', overflow: 'initial' }">
        <div :style="{ padding: '24px', background: '#fff', textAlign: 'center', minHeight:'75vh' }">
         <router-view></router-view>
        </div>
      </a-layout-content>
      <a-layout-footer :style="{ textAlign: 'center' }">
        IML © 2021 Created by Kyler
      </a-layout-footer>
    </a-layout>
      <a-drawer
        title="系统设置"
        placement="left"
        :width="450"
        :closable="true"
        :visible="configVisible"
        @close="configCloseHandler"
      >
      <a-card title="修改密码" style="width: 100%">
        <a-form-model :model="updatePwdForm" >
          <a-form-model-item label="旧密码">
            <a-input-password v-model="updatePwdForm.oldPwd" />
          </a-form-model-item>
          <a-form-model-item label="新密码">
            <a-input-password v-model="updatePwdForm.pwd" />
          </a-form-model-item>
          <a-form-model-item label="重复密码">
            <a-input-password v-model="updatePwdForm.rePwd" />
          </a-form-model-item>
        </a-form-model>
        <a-button type="primary" :loading="updatePwding" :disabled="updatePwding" @click="submitPwd" block>
          保存
        </a-button>
      </a-card>
    </a-drawer>
  </a-layout>
</template>
<script>
import Vue from 'vue'
import axios from 'axios'

export default {
  data:function(){
    return {
      user:{},
      updatePwdForm:{oldPwd:"",pwd:"",rePwd:""},
      configVisible:false,
      updatePwding:false,
    }
  },
  mounted:function(){
    let token = window.sessionStorage.getItem("token");
    if(token){
      axios.defaults.headers.token = token;
      this.$axios.post("/check").then(resp=>{
        if(resp.data && resp.data["code"] == 200){
          this.user = resp.data.data;
          Vue.prototype.currentUser = resp.data.data;
          this.$notification.success({
            message: '欢迎:'+this.currentUser.userName
          });
          this.$ready();
        }else{
          this.$notification.error({
            message: "登录状态失效",
            description:
              resp.data.msg
          });
          window.location.hash = "#/";
        }
      });
    }else{
      window.location.hash = "";
    }
  },
  methods: {
    submitPwd(){
      if(!this.updatePwdForm.pwd || !this.updatePwdForm.oldPwd){
        this.$notification.error({
            message: '请输入旧密码和新密码'
          });
        return;
      }

      if(this.updatePwdForm.pwd != this.updatePwdForm.rePwd){
         this.$notification.error({
            message: '两次输入新密码不一致'
          });
        return;
      }

      this.updatePwding = true;

      let param = {
        oldPwd : this.$md5(this.updatePwdForm.oldPwd),
        pwd : this.$md5(this.updatePwdForm.pwd),
      }

      this.$axios.post("/admin/update-pwd", param).then(resp=>{
        this.updatePwding = false;
        if(resp.data && resp.data["code"] == 200){
          this.$notification.success({
            message: '密码修改成功!',
            description: '下次登陆请使用新密码,如果遗忘可以联系管理员重置'
          });
          this.updatePwdForm = {oldPwd:"",pwd:"",rePwd:""};
        }else{
          this.$notification.error({
            message: '密码修改失败',
            description:
              resp.data.msg,
          });
        }
      })
    },
    openConfig(){
      this.updatePwdForm = {oldPwd:"",pwd:"",rePwd:""};
      this.configVisible = true;
    },
    configCloseHandler(){
      this.configVisible = false;
    },
    getDefaultNavKey(){
      return window.location.hash.substring(1);
    },

    mainMenuClick(menu){
      window.location.hash = "#"+menu.key;
    }
    ,
    logout(){
      Vue.prototype.currentUser = null;
      axios.defaults.headers.token = "";
      window.sessionStorage.removeItem("token");
      window.location.hash = "#/";
    }
    
  }
}
</script>
<style>
#components-layout-demo-fixed-sider .logo {
  height: 88px;
  width: 100%;
  padding: 18px;
  background: #222;
}
</style>

